import PropTypes from "prop-types"
import React from "react"

// css
import './countdown.css'

const Countdown = ({date}) => {
    return (
        <div className="countdown-container flex align-center">
            {/* <div className="uppercase">Countdown to EMM</div> */}
        </div>
    )
};

export default Countdown;